import { IAccount } from '@interfaces/entities/account/account'
import { Account } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { getFullName } from '@utils/getFullName'

export function accountSerializer(account: Account): IAccount {
  const name = getFullName(
    account.firstName,
    account.lastName,
    account.middleName,
  )

  return {
    ...account,
    name,
  } as IAccount
}
