// import { accountAdapter } from '@adapters/accountAdapter'
import { shopAdapter } from '@adapters/shopAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
// import { IAccount } from '@interfaces/entities/account/account'
import { IShop } from '@interfaces/entities/shop/shop'
// import { IShopFollower } from '@interfaces/entities/shopFollower/shopFollower'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { apiSlice, invalidatesList, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { setActiveShop } from '@redux/site/siteSlice'
import { store } from '@redux/store'
import { EntityState } from '@reduxjs/toolkit'
// import { shopFollowerSerializer } from '@serializers/shopFollowerSerializer'
import { shopSerializer } from '@serializers/shopSerializer'

export const shopEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      ApiTagTypes.SHOP,
      ApiTagTypes.SHOP_ACCOUNT,
      ApiTagTypes.SHOP_FOLLOWER,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllShops: builder.query<EntityState<IShop>, void>({
        query: () => ({
          url: EndpointPaths.SHOP_ALL,
          method: 'GET',
        }),
        transformResponse: (response: { data: IShop[] }) => {
          return shopAdapter.setAll(
            shopAdapter.getInitialState(),
            response?.data.map(shopSerializer),
          )
        },
        providesTags: (result: EntityState<IShop> | undefined) =>
          providesList(result?.ids, ApiTagTypes.SHOP),
      }),
      getAccountShops: builder.query<EntityState<IShop>, void>({
        query: () => ({
          url: EndpointPaths.SHOP,
          method: 'GET',
        }),
        transformResponse: (response: { data: IShop[] }) => {
          const dispatch = store.dispatch
          const state = store.getState()
          const shops = shopAdapter.setAll(
            shopAdapter.getInitialState(),
            response?.data.map(shopSerializer),
          )
          if (response.data.length > 0 && !state.site.activeShop) {
            const firstId = shops.ids[0]
            const firstShop = shopAdapter
              .getSelectors()
              .selectById(shops, firstId)
            firstShop && dispatch(setActiveShop({ shop: firstShop as Shop }))
          }
          return shops
        },
        providesTags: (result: EntityState<IShop> | undefined) =>
          providesList(result?.ids, ApiTagTypes.SHOP_ACCOUNT),
      }),
      // getFollowers: builder.query<EntityState<IShopFollower>, string>({
      //   query: (shopId: string) => ({
      //     url: `${EndpointPaths.FOLLOWERS}/${shopId}`,
      //     method: 'GET',
      //   }),
      //   transformResponse: (response: { data: ShopFollower[] }) =>
      //     accountAdapter.setAll(
      //       accountAdapter.getInitialState(),
      //       response?.data.map(shopFollowerSerializer),
      //     ),
      //   providesTags: (result: EntityState<IShopFollower> | undefined) =>
      //     providesList(result?.ids, ApiTagTypes.SHOP_FOLLOWER),
      // }),
      createShop: builder.mutation({
        query: (body) => ({
          url: EndpointPaths.SHOP,
          method: 'POST',
          body: { ...body },
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SHOP),
          invalidatesList(ApiTagTypes.SHOP_ACCOUNT),
        ],
      }),
      editShop: builder.mutation({
        query: ({ shopId, ...body }) => ({
          url: `${EndpointPaths.SHOP}/${shopId}`,
          method: 'PATCH',
          body: { ...body },
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SHOP),
          invalidatesList(ApiTagTypes.SHOP_ACCOUNT),
        ],
      }),
      deleteShop: builder.mutation({
        query: (shopId) => ({
          url: `${EndpointPaths.SHOP}/${shopId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SHOP),
          invalidatesList(ApiTagTypes.SHOP_ACCOUNT),
        ],
      }),
      setStatus: builder.mutation({
        query: ({ shopId, isActive }) => ({
          url: isActive
            ? `${EndpointPaths.SHOP_DISABLE}/${shopId}`
            : `${EndpointPaths.SHOP_ENABLE}/${shopId}`,
          method: 'POST',
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SHOP),
          invalidatesList(ApiTagTypes.SHOP_ACCOUNT),
        ],
      }),
      removeShopUser: builder.mutation({
        query: (shopAccountId) => ({
          url: `${EndpointPaths.SHOP_REMOVE_ACCOUNT}/${shopAccountId}`,
          method: 'DELETE',
        }),
        invalidatesTags: [
          invalidatesList(ApiTagTypes.SHOP),
          invalidatesList(ApiTagTypes.SHOP_ACCOUNT),
        ],
      }),
      createPost: builder.mutation({
        query: ({ shopId, body }: { shopId: string; body: string }) => ({
          url: `${EndpointPaths.POST}/${shopId}`,
          method: 'POST',
          body: { body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.SHOP_POST)],
      }),
    }),
  })

export const {
  useGetAllShopsQuery,
  useGetAccountShopsQuery,
  useCreateShopMutation,
  useDeleteShopMutation,
  useEditShopMutation,
  useCreatePostMutation,
  useSetStatusMutation,
  useRemoveShopUserMutation,
} = shopEndpoints
