import { orderItemAdapter } from '@adapters/orderItemAdapter'
import { IAccount } from '@interfaces/entities/account/account'
import { IOrder } from '@interfaces/entities/order/order'
import { IOrderItem } from '@interfaces/entities/orderItem/orderItem'
import { Order } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { accountSerializer } from './accountSerializer'
import { orderItemSerializer } from './orderItemSerializer'

interface _Order extends Order {
  Account: IAccount
  OrderItem: IOrderItem[]
}

export function orderSerializer(orders: _Order[]): IOrder[] {
  const _orders = orders.reduce(
    (acc: { [x: string]: IOrder }, order: _Order) => {
      acc[order.accountId] = acc[order.accountId] || {
        items: orderItemAdapter.getInitialState(),
        account: {},
      }
      acc[order.accountId].id = order.id
      acc[order.accountId].account = accountSerializer(order?.Account)
      acc[order.accountId].items = orderItemAdapter.setMany(
        acc[order.accountId].items,
        order.OrderItem.map(orderItemSerializer),
      )

      return acc
    },
    {},
  )

  return Object.values(_orders) as IOrder[]
}
