// Routing Paths
export enum RoutePaths {
  // AUTH ROUTES
  LOGIN = '/login',
  SIGNUP = '/signup',
  CONNECT = '/connect',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFY_EMAIL = '/verify/:code',
  LOGOUT = '/logout',
  RESEND_VERIFICATION_EMAIL = '/resend-verification-email',
  RESET_PASSWORD = '/reset-password/:code',

  // ADMIN ROUTES
  ADMIN = '/admin',
  ADMIN_OVERVIEW = 'overview',
  ADMIN_INVITE = 'invite',
  ADMIN_SHOPS = 'platform-shops',

  ADMIN_BRANDS = 'brands',
  ADMIN_BRANDS_LIST = 'list',
  ADMIN_BRANDS_CREATE = 'create',
  ADMIN_BRANDS_DETAILS = 'details',
  ADMIN_BRANDS_MANAGE = 'manage',

  ADMIN_PRODUCTS = 'products',
  ADMIN_PRODUCTS_LIST = 'list',
  ADMIN_PRODUCTS_DETAILS = 'details',

  ADMIN_ACCOUNTS = 'accounts',
  ADMIN_ACCOUNTS_LIST = 'list',
  ADMIN_ACCOUNTS_DETAILS = 'details',

  // ACCOUNT ROUTES
  ACCOUNT = '/account',
  ACCOUNT_DETAILS = 'details',
  ACCOUNT_CHANGE_PASSWORD = 'change-password',

  // DASHBOARD ROUTES
  DASHBOARD = '/dashboard',

  // PRODUCT ROUTES
  PRODUCTS = '/products',
  PRODUCTS_THRIFT = 'thrift',
  PRODUCTS_VINTAGE = 'vintage',
  PRODUCTS_CREATE = 'create',
  PRODUCTS_DETAILS = 'details',
  PRODUCTS_MANAGE = 'manage',

  // SHOP ROUTES
  SHOPS = '/shops',
  SHOPS_LIST = 'list',
  SHOPS_CREATE = 'create',
  SHOPS_PROFILE = 'profile',
  SHOPS_MANAGE = 'manage',

  // OTHERS
  WISHLISTS = '/wishlists',

  // POSTS
  POSTS = '/posts',

  // ORDERS
  ORDERS = '/orders',
}
