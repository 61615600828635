import { RoutePaths } from '@interfaces/routePaths'
import { Base } from '@views/Products/Base'
import { Details } from '@views/Products/Details'
import { Manage } from '@views/Products/manage/Manage'
import { Thrift } from '@views/Products/Thrift'
import { Vintage } from '@views/Products/Vintage'
import { RouteObject } from 'react-router-dom'

export const PRODUCT_PATHS: RouteObject[] = [
  {
    path: RoutePaths.PRODUCTS,
    element: <Base />,
    children: [
      {
        path: RoutePaths.PRODUCTS_VINTAGE,
        element: <Vintage />,
        children: [
          {
            path: RoutePaths.PRODUCTS_CREATE,
            element: <Manage />,
          },
        ],
      },
      {
        path: RoutePaths.PRODUCTS_THRIFT,
        element: <Thrift />,
      },
      {
        path: RoutePaths.PRODUCTS_CREATE,
        element: <Manage />,
      },
      // {
      //   path: `${RoutePaths.PRODUCTS_DETAILS}/:productId`,
      //   element: <Details />,
      // },
      // {
      //   path: `${RoutePaths.PRODUCTS_DETAILS}/:productId/${RoutePaths.PRODUCTS_MANAGE}/:mode`,
      //   element: <Manage />,
      // },
    ],
  },
]
