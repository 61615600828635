import { RoutePaths } from '@interfaces/routePaths'
import { Base } from '@views/Orders/Base'
import { List } from '@views/Shops/List'
import { Manage } from '@views/Shops/manage/Manage'
import { Details } from '@views/Shops/profile/Details'
import { RouteObject } from 'react-router-dom'

export const ORDER_PATHS: RouteObject[] = [
  {
    path: RoutePaths.ORDERS,
    element: <Base />,
    children: [
      {
        path: RoutePaths.SHOPS_LIST,
        element: <List />,
        children: [
          {
            path: RoutePaths.SHOPS_CREATE,
            element: <Manage />,
          },
        ],
      },
      {
        path: `${RoutePaths.SHOPS_PROFILE}/:shopId`,
        element: <Details />,
        children: [
          {
            path: `${RoutePaths.SHOPS_MANAGE}/:mode`,
            element: <Manage />,
          },
        ],
      },
    ],
  },
]
