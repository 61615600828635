import { EndpointPaths } from '@interfaces/endpointPaths'
import { EntityId } from '@reduxjs/toolkit'

export function paramToValue(
  path: EndpointPaths,
  param: string,
  val: string,
): string {
  return path.replace(`:${param}`, val)
}

type ParamToValue = {
  param: string
  value: string | EntityId
}
export const paramsToValue = (path: EndpointPaths, params: ParamToValue[]) => {
  let newPath = path as string
  params.forEach(({ param, value }) => {
    newPath = newPath.replace(`:${param}`, value.toString())
  })
  return newPath
}
