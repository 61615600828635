import { IOrderItem } from '@interfaces/entities/orderItem/orderItem'
import { productSerializer } from './productSerializer'

export function orderItemSerializer(orderItem: IOrderItem): IOrderItem {
  const { Product } = orderItem

  const product = productSerializer(Product)

  return {
    ...orderItem,
    id: orderItem.permalink,
    product,
  }
}
