import { productAdapter } from '@adapters/productAdapter'
import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { FormError } from '@components/FormError/FormError'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { IProductAsset } from '@interfaces/entities/productAsset/productAsset'
import { MutationError } from '@interfaces/mutationError'
import {
  ActionIcon,
  Box,
  Button,
  CardSection,
  FileButton,
  Group,
  Image,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import {
  getSubcategories,
  ProductCategoryEnum,
  ProductColorEnum,
  ProductConditionEnum,
  ProductDepartmentEnum,
  ProductSizeEnum,
  ProductTypeEnum,
} from '@nabthisdev/nabthis-types'
import {
  useEditProductMutation,
  useGetProductsQuery,
} from '@redux/product/productEndpoints'
import { selectActiveShop } from '@redux/site/siteSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  editVintageProductInitialState,
  editVintageProductSchema,
  EditVintageProductState,
} from '@schemas/product/editVintageProduct'
import {
  IconArrowLeft,
  IconArrowRight,
  IconBarcode,
  IconCategory,
  IconCloudUpload,
  IconColorFilter,
  IconCurrencyDollar,
  IconHanger,
  IconPhoto,
  IconRulerMeasure,
  IconSparkles,
  IconTrademark,
} from '@tabler/icons-react'
import { removeEmptyStringFields } from '@utils/removeEmptyStringFields'
import { arrayMoveImmutable } from 'array-move'
import currency from 'currency.js'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const EditVintageProduct = (): JSX.Element => {
  const navigate = useNavigate()
  const { productId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()
  const [subcategories, setSubcategories] = useState<
    { label: string; value: string }[]
  >([])
  const [editProduct, { isSuccess }] = useEditProductMutation()
  const shop = useAppSelector(selectActiveShop)
  const type = ProductTypeEnum.VINTAGE

  const { data: productEntityState } = useGetProductsQuery(
    shop && shop.id
      ? {
          shopId: shop.id,
          type,
        }
      : skipToken,
  )

  const product =
    productEntityState &&
    productAdapter.getSelectors().selectById(productEntityState, productId)

  const productAssets =
    productEntityState &&
    product?.assets &&
    productAssetAdapter
      .getSelectors()
      .selectAll(product.assets)
      .map((photo: IProductAsset) => {
        return {
          ...photo,
          photo: photo.thumbnail,
        }
      })

  const {
    onSubmit,
    isDirty,
    getInputProps,
    values,
    insertListItem,
    removeListItem,
    setFieldValue,
  } = useForm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValues: editVintageProductInitialState(product as any),
    validate: yupResolver(editVintageProductSchema),
  })

  const handleClose = () => navigate(`../details/${productId}`)

  const handleSubmit = async (values: EditVintageProductState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      const strippedValues = removeEmptyStringFields(values)
      const { photos: photoList, ...rest } = values

      const originalPhotos = photoList?.reduce((acc, photo, index) => {
        if (photo.id) {
          acc.push({
            id: photo.id,
            position: index,
          })
        }

        return acc
      }, [] as unknown[])

      const originalCaptions = photoList?.reduce((acc, photo) => {
        if (photo.id) {
          acc.push(photo.caption ?? '')
        }

        return acc
      }, [] as string[])

      const uploadedPhotos = photoList?.reduce((acc, photo) => {
        if (!photo.id && photo.photo) {
          acc.push(photo.photo)
        }

        return acc
      }, [] as unknown[])

      const uploadedPositions = photoList?.reduce((acc, photo, index) => {
        if (!photo.id && photo.photo) {
          acc.push({
            position: index,
          })
        }

        return acc
      }, [] as unknown[])

      const uploadedCaptions = photoList?.reduce((acc, photo) => {
        if (!photo.id && photo.photo) {
          acc.push(photo.caption ?? '')
        }

        return acc
      }, [] as string[])

      const price = parseInt(
        String(Math.ceil(currency(rest.price).value * 100)),
      )

      const _values = {
        ...strippedValues,
        type,
        shopId: shop.id,
        originalPhotos,
        originalCaptions,
        uploadedPhotos,
        uploadedCaptions,
        uploadedPositions,
        price,
      }

      await editProduct(_values).unwrap()

      showNotification({
        message: `${product?.title} edited!`,
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setFieldValue('title', product?.title ?? '')
    setFieldValue('customBrand', product?.customBrand ?? '')
    setFieldValue('sku', product?.sku ?? '')
    setFieldValue('category', product?.category ?? '')
    setFieldValue('description', product?.description ?? '')
    setFieldValue('price', String(product?.price) ?? '')
    setFieldValue('size', product?.size ?? '')
    setFieldValue('condition', product?.condition ?? '')
    setFieldValue('department', product?.department ?? '')
    setFieldValue('color', product?.color ?? '')
    setFieldValue(
      'photos',
      productAssets ?? [
        {
          id: null,
          photo: null,
          thumbnail: null,
          caption: '',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      ],
    )
    setFieldValue('measurements', product?.measurements ?? '')
    setFieldValue('imperfections', product?.imperfections ?? '')

    const _subcats = getSubcategories(values.category as ProductCategoryEnum)

    if (_subcats) {
      setSubcategories(
        Object.values(_subcats).map((subcategory: string) => ({
          label: subcategory,
          value: subcategory,
        })),
      )
    }
  }, [product, values.category])

  const COLOR_VALUES = Object.values(ProductColorEnum).map((color: string) => ({
    label: color,
    value: color,
  }))

  const SIZE_VALUES = Object.values(ProductSizeEnum).map((size: string) => ({
    label: size,
    value: size,
  }))

  const CONDITION_VALUES = Object.values(ProductConditionEnum).map(
    (condition: string) => ({
      label: condition,
      value: condition,
    }),
  )

  const DEPARTMENT_VALUES = Object.values(ProductDepartmentEnum).map(
    (department: string) => ({
      label: department,
      value: department,
    }),
  )

  const CATEGORY_VALUES = Object.values(ProductCategoryEnum).map(
    (category: string) => ({
      label: category,
      value: category,
    }),
  )

  const addPhotoItem = () => {
    insertListItem('photos', {
      photo: null,
      caption: '',
    })
  }

  const removePhotoItem = (index: number) => {
    if (values.photos && values.photos.length > 1)
      removeListItem('photos', index)
  }

  const movePhotoItem = (oldIndex: number, newIndex: number) => {
    setFieldValue(
      'photos',
      arrayMoveImmutable(values?.photos ?? [], oldIndex, newIndex),
    )
  }

  const getPreview = (index: number) => {
    if (!values.photos?.[index]?.photo) return <></>
    const imageUrl = URL.createObjectURL(values.photos?.[index]?.photo as Blob)

    return (
      <Box
        w={185}
        h={185}
        sx={(theme) => ({
          overflow: 'hidden',
          border: `solid 1px ${theme.colors.gray[4]}`,
        })}
      >
        <Image
          src={imageUrl}
          onLoad={() => URL.revokeObjectURL(imageUrl)}
          sx={(theme) => ({
            borderRight: `solid 1px ${theme.colors.gray[4]}`,
          })}
        />
      </Box>
    )
  }

  const photoFields =
    values.photos &&
    values.photos.map((_, index) => {
      return values?.photos?.[index].id ? (
        <Group key={`photos-${index}`} spacing="xs" align="flex-start">
          <Stack spacing="xs">
            <Box
              w={185}
              h={185}
              sx={(theme) => ({
                overflow: 'hidden',
                border: `solid 1px ${theme.colors.gray[4]}`,
              })}
            >
              <Image
                src={values?.photos?.[index].photo as string}
                sx={(theme) => ({
                  borderRight: `solid 1px ${theme.colors.gray[4]}`,
                })}
              />
            </Box>
            <Group grow spacing="xs">
              <ActionIcon
                variant="outline"
                color="gray"
                disabled={
                  index === 0 ||
                  !values?.photos?.[index].photo ||
                  !values?.photos?.[index - 1].photo
                }
                onClick={() => movePhotoItem(index, index - 1)}
              >
                <IconArrowLeft size="1rem" />
              </ActionIcon>
              <ActionIcon
                variant="outline"
                disabled={
                  !values?.photos?.[index].photo ||
                  index + 1 === values?.photos?.length ||
                  !values?.photos?.[index + 1].photo
                }
                onClick={() => movePhotoItem(index, index + 1)}
              >
                <IconArrowRight size="1rem" />
              </ActionIcon>
            </Group>

            <TextInput
              placeholder="Optional Caption"
              style={{ flexGrow: 1 }}
              {...getInputProps(`photos.${index}.caption`)}
            />
            {values?.photos?.[index].photo && index !== 0 && (
              <Button
                variant="outline"
                size="xs"
                color="red"
                onClick={() => removePhotoItem(index)}
              >
                Remove
              </Button>
            )}
            {values?.photos?.[index].photo && index === 0 && (
              <Button variant="outline" size="xs" color="green">
                Primary
              </Button>
            )}
          </Stack>
        </Group>
      ) : (
        <Group key={`photos-${index}`} spacing="xs" align="flex-start">
          <Stack spacing="xs">
            {values?.photos?.[index].id && (
              <Box
                w={185}
                h={185}
                sx={(theme) => ({
                  overflow: 'hidden',
                  border: `solid 1px ${theme.colors.gray[4]}`,
                })}
              >
                <Image
                  src={values?.photos?.[index].photo as string}
                  sx={(theme) => ({
                    borderRight: `solid 1px ${theme.colors.gray[4]}`,
                  })}
                />
              </Box>
            )}

            {values?.photos?.[index].photo &&
              !values?.photos?.[index].id &&
              getPreview(index)}

            {!values?.photos?.[index].photo && !values?.photos?.[index].id && (
              <Box
                p="md"
                w={185}
                h={185}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `solid 1px ${theme.colors.gray[4]}`,
                  backgroundColor: theme.colors.gray[1],
                })}
              >
                <IconPhoto size="2rem" />
              </Box>
            )}
            <Group grow spacing="xs">
              <ActionIcon
                variant="outline"
                color="gray"
                disabled={
                  !values?.photos?.[index].photo ||
                  index === 0 ||
                  !values?.photos?.[index - 1].photo
                }
                onClick={() => movePhotoItem(index, index - 1)}
              >
                <IconArrowLeft size="1rem" />
              </ActionIcon>
              <ActionIcon
                variant="outline"
                disabled={
                  !values?.photos?.[index].photo ||
                  index + 1 === values?.photos?.length ||
                  !values?.photos?.[index + 1].photo
                }
                onClick={() => movePhotoItem(index, index + 1)}
              >
                <IconArrowRight size="1rem" />
              </ActionIcon>
            </Group>
            {!values?.photos?.[index].photo && !values?.photos?.[index].id && (
              <FileButton
                accept="image/png,image/jpeg,image/jpg"
                {...getInputProps(`photos.${index}.photo`)}
              >
                {(props) => (
                  <Button
                    variant="outline"
                    leftIcon={<IconCloudUpload size="0.9rem" />}
                    disabled={values?.photos?.[index].photo ? true : false}
                    {...props}
                  >
                    Upload Image
                  </Button>
                )}
              </FileButton>
            )}

            <TextInput
              placeholder="Optional Caption"
              style={{ flexGrow: 1 }}
              {...getInputProps(`photos.${index}.caption`)}
            />
            {values?.photos?.[index].photo && index !== 0 && (
              <Button
                variant="outline"
                size="xs"
                color="red"
                onClick={() => removePhotoItem(index)}
              >
                Remove
              </Button>
            )}
          </Stack>
        </Group>
      )
    })

  return (
    <Section title="Edit Vintage Product">
      <CardSection p="md">
        <form
          onSubmit={onSubmit((values: EditVintageProductState) =>
            handleSubmit(values),
          )}
        >
          <Stack>
            {!isSuccess && formError && <FormError error={formError} />}

            <TextInput
              label="Title"
              description="Enter a title for the product"
              placeholder="Title"
              data-autofocus
              withAsterisk
              {...getInputProps('title')}
            />
            <CardSection p="md" withBorder>
              <SimpleGrid cols={3} spacing="xs">
                {photoFields}
                <Group spacing="xs" align="flex-start">
                  <Stack>
                    <Box
                      p="md"
                      w={185}
                      h={185}
                      sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `solid 1px ${theme.colors.gray[4]}`,
                        backgroundColor: theme.colors.gray[0],
                        cursor: 'pointer',
                      })}
                      onClick={addPhotoItem}
                    >
                      <IconCloudUpload size="2rem" />
                    </Box>
                    <Text
                      align="center"
                      size="sm"
                      color="violet"
                      style={{ cursor: 'pointer' }}
                      onClick={addPhotoItem}
                    >
                      Add Another Image
                    </Text>
                  </Stack>
                </Group>
              </SimpleGrid>
            </CardSection>

            <Textarea
              label="Description"
              description="Enter a brief description of the product"
              placeholder="Description"
              autosize
              minRows={3}
              withAsterisk
              {...getInputProps('description')}
            />
            <TextInput
              label="SKU"
              description="Enter the SKU of your product"
              icon={<IconBarcode size="1rem" />}
              placeholder="SKU"
              withAsterisk
              {...getInputProps('sku')}
            />
            <TextInput
              label="Price"
              description="Enter Price in USD"
              icon={<IconCurrencyDollar size="1rem" />}
              placeholder="Price"
              withAsterisk
              {...getInputProps('price')}
            />
            <Select
              label="Category"
              description="Select the category of the product. Such as: Sweatshirts, Pants, Jackets, etc."
              icon={<IconHanger size="1rem" />}
              placeholder="Select Category"
              data={CATEGORY_VALUES}
              searchable
              nothingFound="No matches found"
              clearable
              withAsterisk
              {...getInputProps('category')}
            />
            {subcategories.length > 0 && (
              <Select
                label="Subcategory"
                description="Select the subcategory of the product."
                icon={<IconHanger size="1rem" />}
                placeholder="Select Subcategory"
                data={subcategories}
                searchable
                nothingFound="No matches found"
                clearable
                withAsterisk
                {...getInputProps('subcategory')}
              />
            )}
            <TextInput
              label="Brand"
              placeholder="Brand"
              description="Enter the brand/manufacturer name of the product"
              icon={<IconTrademark size="1.1rem" />}
              withAsterisk
              {...getInputProps('customBrand')}
            />
            <Select
              label="Department"
              description="Which department does this product belong to?"
              icon={<IconCategory size="1rem" />}
              placeholder="Select Department"
              data={DEPARTMENT_VALUES}
              searchable
              nothingFound="No matches found"
              clearable
              withAsterisk
              {...getInputProps('department')}
            />
            <Select
              label="Size"
              description="Please refer to our size chart and select the appropriate sizing"
              icon={<IconRulerMeasure size="1rem" />}
              placeholder="Select Size"
              data={SIZE_VALUES}
              searchable
              nothingFound="No matches found"
              clearable
              withAsterisk
              {...getInputProps('size')}
            />
            <Select
              label="Color"
              description="Select a color that best describes the product"
              icon={<IconColorFilter size="1rem" />}
              placeholder="Select Color"
              data={COLOR_VALUES}
              searchable
              nothingFound="No matches found"
              clearable
              withAsterisk
              {...getInputProps('color')}
            />
            <Select
              label="Condition"
              description="Please follow our guidelines carefully when selecting the condition"
              icon={<IconSparkles size="1rem" />}
              placeholder="Select Condition"
              data={CONDITION_VALUES}
              searchable
              nothingFound="No matches found"
              clearable
              withAsterisk
              {...getInputProps('condition')}
            />
            <Textarea
              label="Stains and Imperfections"
              description="Enter information on stains and imperfections"
              placeholder="Stains and Imperfections"
              autosize
              minRows={3}
              {...getInputProps('imperfections')}
            />
            <Textarea
              label="Measurements"
              description="Enter custom measurements, if any"
              placeholder="Measurements (IE: Chest - 15)"
              autosize
              minRows={3}
              {...getInputProps('measurements')}
            />

            <Group position="right">
              <Button variant="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" loading={isDirty() && isSubmitting}>
                Edit
              </Button>
            </Group>
          </Stack>
        </form>
      </CardSection>
    </Section>
  )
}
