import * as Yup from 'yup'

export const editVintageProductSchema = Yup.object({
  title: Yup.string().required('Name is required'),
  customBrand: Yup.string().required('Brand is required'),
  sku: Yup.string().required('SKU is required'),
  category: Yup.string().required('Category is required'),
  subcategory: Yup.string().optional(),
  description: Yup.string().required('Description is required'),
  color: Yup.string().required('Color is required'),
  price: Yup.string().required('Price is required'),
  size: Yup.string().required('Size is required'),
  condition: Yup.string().required('Condition is required'),
  department: Yup.string().required('Department is required'),
  imperfections: Yup.string().optional(),
  measurements: Yup.string().optional(),
  photos: Yup.array().of(
    Yup.object({
      id: Yup.string().optional().nullable(),
      thumbnail: Yup.string().optional().nullable(),
      photo: Yup.mixed().optional().nullable(),
      caption: Yup.string().optional().nullable(),
    }),
  ),
})

export type EditVintageProductState = Yup.InferType<
  typeof editVintageProductSchema
>

export const editVintageProductInitialState: (
  defaults: Partial<EditVintageProductState>,
) => EditVintageProductState = (defaults: Partial<EditVintageProductState>) => {
  return {
    title: defaults?.title ?? '',
    customBrand: defaults?.customBrand ?? '',
    sku: defaults?.sku ?? '',
    category: defaults?.category ?? '',
    subcategory: defaults?.subcategory ?? '',
    description: defaults?.description ?? '',
    color: defaults?.color ?? '',
    price: defaults?.price ?? '',
    size: defaults?.size ?? '',
    condition: defaults?.condition ?? '',
    department: defaults?.department ?? '',
    imperfections: defaults?.imperfections ?? '',
    measurements: defaults?.measurements ?? '',
    photos: defaults.photos ?? [
      {
        id: null,
        photo: null,
        thumbnail: null,
        caption: '',
      },
    ],
  }
}
