import { shopAdapter } from '@adapters/shopAdapter'
import { Logo } from '@components/Logo/Logo'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { IShop } from '@interfaces/entities/shop/shop'
import {
  Anchor,
  AppShell,
  Box,
  Group,
  Header,
  Navbar,
  ScrollArea,
  Select,
  Title,
} from '@mantine/core'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { selectAccountInformation } from '@redux/account/accountSlice'
import { useGetAccountShopsQuery } from '@redux/shop/shopEndpoints'
import {
  selectActiveSection,
  selectActiveShopId,
  setActiveShop,
} from '@redux/site/siteSlice'
import { EntityState } from '@reduxjs/toolkit'
import { IconBuildingStore } from '@tabler/icons-react'
import { getDefaultLink } from '@utils/getDefaultLink'
import { ReactNode, useEffect, useState } from 'react'
import { NavLinks } from './NavLinks'
import { User } from './User'

export interface ShellProps {
  children: ReactNode | ReactNode[]
}

export const Shell = ({ children }: ShellProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectAccountInformation)
  // const activeShop = useAppSelector(selectActiveShop)
  const activeShopId = useAppSelector(selectActiveShopId)
  const activeSection = useAppSelector(selectActiveSection)
  const { data: shopEntity } = useGetAccountShopsQuery()

  const shops = shopEntity
    ? shopAdapter.getSelectors().selectAll(shopEntity)
    : []

  const [sellerSelectValue, setSellerSelectValue] = useState<string>(
    activeShopId || shops[0].id || '',
  )

  const logoLink = getDefaultLink(user.role)

  const SELECT_VALUES = shops.map((shop: Shop) => ({
    label: shop.name,
    value: shop.id ?? '',
  }))

  useEffect(() => {
    if (activeShopId) {
      setSellerSelectValue(activeShopId)
    }
  }, [shopEntity, activeShopId])

  const handleChange = (val: string) => {
    const shop =
      shopAdapter
        .getSelectors()
        .selectById(shopEntity as EntityState<IShop>, val) ?? ({} as Shop)

    dispatch(setActiveShop({ shop: shop as IShop }))
  }

  return (
    <AppShell
      layout="alt"
      padding={0}
      fixed
      navbar={
        <Navbar width={{ base: 275 }} height="100%">
          <Navbar.Section pt="lg" pl="lg" pb="xl">
            <Anchor href={logoLink}>
              <Logo size="md" />
            </Anchor>
          </Navbar.Section>

          <Navbar.Section grow component={ScrollArea}>
            <NavLinks />
          </Navbar.Section>

          <Navbar.Section>
            <User />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <>
          <Header height={60} px="md">
            <Group h="100%" align="center" position="apart">
              <Title order={4}>{activeSection}</Title>
              {shops && shops.length > 0 && (
                <Group>
                  <Select
                    placeholder="Current Shop"
                    data={SELECT_VALUES ?? []}
                    onChange={handleChange}
                    value={sellerSelectValue}
                    icon={<IconBuildingStore size="1rem" />}
                  />
                </Group>
              )}
            </Group>
          </Header>
        </>
      }
    >
      <Box
        sx={(theme) => ({
          minHeight: '100%',
          backgroundColor: theme.colors.gray[0],
        })}
      >
        {children}
      </Box>
    </AppShell>
  )
}
