import { Content } from '@components/Content/Content'
import { useSetSection } from '@hooks/useSetSection'
import { List } from './List'

export const Base = (): JSX.Element => {
  useSetSection('Orders')

  return (
    <Content>
      <List />
    </Content>
  )
}
