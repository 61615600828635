// import { accountAdapter } from '@adapters/accountAdapter'
import { orderAdapter } from '@adapters/orderAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { IOrder } from '@interfaces/entities/order/order'
import { IOrderItem } from '@interfaces/entities/orderItem/orderItem'
import { apiSlice, invalidatesList, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'
import { orderSerializer } from '@serializers/orderSerializer'
import { paramToValue } from '@utils/paramToValue'

export const orderEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ORDER],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOrders: builder.query<EntityState<IOrder>, string>({
        query: (shopId: string) => ({
          url: paramToValue(EndpointPaths.ORDER, 'shopId', shopId),
          method: 'GET',
        }),
        transformResponse: (response: { data: IOrderItem[] }) =>
          orderAdapter.setAll(
            orderAdapter.getInitialState(),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            orderSerializer(response.data as any),
          ),
        providesTags: (result: EntityState<IOrder> | undefined) =>
          providesList(result?.ids, ApiTagTypes.ORDER),
      }),
      updateOrder: builder.mutation({
        query: ({ shopId, body }) => {
          return {
            url: paramToValue(EndpointPaths.ORDER, 'shopId', shopId),
            method: 'PATCH',
            body: { ...body },
          }
        },
        invalidatesTags: [invalidatesList(ApiTagTypes.ORDER)],
      }),
    }),
  })

export const { useGetOrdersQuery, useUpdateOrderMutation } = orderEndpoints
