// RTK Query Tags
export enum ApiTagTypes {
  SHOP = 'Shop',
  SHOP_ACCOUNT = 'ShopAccount',
  SHOP_POST = 'ShopPost',
  SHOP_FOLLOWER = 'ShopFollower',
  BRAND = 'Brand',
  PRODUCT = 'Product',
  PRODUCT_ADMIN = 'ProductAdmin',
  ACCOUNT = 'Account',
  WISHLIST = 'Wishlist',
  ORDER = 'Order',
}
