import { FormError } from '@components/FormError/FormError'
import { SplitPanel } from '@components/SplitPanel/SplitPanel'
import { useAppDispatch } from '@hooks/redux'
import { useSetSection } from '@hooks/useSetSection'
import { MutationError } from '@interfaces/mutationError'
import { RoutePaths } from '@interfaces/routePaths'
import { Anchor, Button, PasswordInput, Stack, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { setAccountInformation } from '@redux/account/accountSlice'
import { useLoginMutation } from '@redux/auth/authEndpoints'
import { setCredentials } from '@redux/auth/authSlice'
import { loginInitialState, loginSchema, LoginState } from '@schemas/auth/login'
import { getDefaultLink } from '@utils/getDefaultLink'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const Login = (): JSX.Element => {
  useSetSection('Login')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [formError, setFormError] = useState<MutationError | null>()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  const [login] = useLoginMutation()

  const { onSubmit, isDirty, getInputProps } = useForm({
    initialValues: loginInitialState(),
    validate: yupResolver(loginSchema),
  })

  const handleSubmit = async (values: LoginState) => {
    if (isSubmitting) return

    const { email, password } = values

    setSubmitting(true)
    setFormError(null)

    try {
      const result = await login({ email, password }).unwrap()
      const { accessToken, refreshToken, account } = result.data

      dispatch(setCredentials({ accessToken, refreshToken, isLoggedIn: true }))
      dispatch(setAccountInformation(account))

      const defaultLink = getDefaultLink(account.role)
      navigate(defaultLink, { replace: false })
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <SplitPanel title="Sign In">
      <form
        onSubmit={onSubmit((values: LoginState) => handleSubmit(values))}
        style={{ width: '100%' }}
      >
        <Stack>
          {formError && <FormError error={formError} />}
          <TextInput
            type="email"
            placeholder="Email"
            {...getInputProps('email')}
          />
          <PasswordInput
            placeholder="Password"
            {...getInputProps('password')}
          />

          <Button type="submit" loading={isDirty() && isSubmitting}>
            Sign In
          </Button>
          <Anchor
            href={RoutePaths.FORGOT_PASSWORD}
            align="center"
            color="dimmed"
          >
            Forgot Password
          </Anchor>
        </Stack>
      </form>
    </SplitPanel>
  )
}
