import { productAdapter } from '@adapters/productAdapter'
import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { IProduct } from '@interfaces/entities/product/product'
import { RoutePaths } from '@interfaces/routePaths'
import { Carousel } from '@mantine/carousel'
import {
  Badge,
  Button,
  CardSection,
  Grid,
  Group,
  Image,
  Input,
  rem,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { ProductTypeEnum, StatusEnum } from '@nabthisdev/nabthis-types'
import { EntityState } from '@reduxjs/toolkit'
import {
  IconBarcode,
  IconCategory,
  IconHanger,
  IconPlus,
  IconRulerMeasure,
  IconSparkles,
  IconTrademark,
} from '@tabler/icons-react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

export interface ListProps {
  type: ProductTypeEnum
  productEntityState: EntityState<IProduct> | undefined
  isLoading: boolean
}

export const List = (props: ListProps): JSX.Element => {
  const { type, productEntityState, isLoading } = props
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')

  const products = productEntityState
    ? productAdapter.getSelectors().selectAll(productEntityState)
    : []

  const handleNavigate = () =>
    navigate(`../${RoutePaths.PRODUCTS_CREATE}?type=${type}`)

  const handleClick = (productId: string) =>
    navigate(`../${RoutePaths.PRODUCTS_DETAILS}/${productId}?type=${type}`)

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.sku.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const productCard = (product: IProduct) => {
    const productAssets = product.assets
      ? productAssetAdapter.getSelectors().selectAll(product.assets)
      : []

    const isActive = product.status === StatusEnum.ACTIVE

    return (
      <Grid.Col key={product.id} span={4}>
        <Stack pt="sm">
          <CardSection
            style={{
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              overflow: 'hidden',
              transition: 'box-shadow 0.2s ease',
              cursor: 'pointer',
              backgroundColor: '#fff',
            }}
            onClick={() => handleClick(product.id)}
          >
            <Group pl="sm" pt="xs" pr="xs">
              <Text fw="bold" size="sm" color="gray.7" lineClamp={1}>
                {product.title}
              </Text>
            </Group>
            <Group p="sm" position="center">
              <Badge variant="filled" color={isActive ? '' : 'gray'}>
                {product.cost}
              </Badge>
              {isActive ? (
                <Badge variant="filled" color="teal.5">
                  Active
                </Badge>
              ) : (
                <Badge variant="filled" color="gray">
                  Inactive
                </Badge>
              )}
            </Group>
            {productAssets.length > 0 && (
              <CardSection onClick={(e) => e.stopPropagation()}>
                <Carousel
                  mx="auto"
                  height={200}
                  withIndicators
                  styles={{
                    root: {
                      ':hover .mantine-Carousel-controls': {
                        opacity: 1,
                      },
                    },
                    controls: {
                      transition: 'opacity 150ms',
                      opacity: 0,
                    },
                    indicator: {
                      width: rem(4),
                      height: rem(4),
                      transition: 'width 250ms ease',
                      '&[data-active]': {
                        width: rem(15),
                      },
                    },
                  }}
                >
                  {productAssets.map((asset) => (
                    <Carousel.Slide key={asset.id}>
                      <Image
                        src={asset.url as string}
                        height={200}
                        fit="cover"
                        styles={{
                          image: {
                            objectPosition: 'top',
                            width: '100%',
                            height: '100%',
                            filter: isActive ? 'none' : 'grayscale(100%)',
                          },
                        }}
                      />
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </CardSection>
            )}
            <Text size="xs" color="gray.7" p="sm">
              <Stack spacing="xs">
                <Group spacing="xs">
                  <IconBarcode size="1rem" />
                  <Text lineClamp={1}>{product.sku}</Text>
                </Group>
                <Group spacing="xs">
                  <IconTrademark size="1rem" />
                  <Text lineClamp={1}>
                    {product.brandName
                      ? product.brandName
                      : product.customBrand}
                  </Text>
                </Group>
                <Group spacing="xs">
                  <IconSparkles size="1rem" />
                  <Text lineClamp={1}>{product.condition}</Text>
                </Group>
                <Group spacing="xs">
                  <IconCategory size="1rem" />
                  <Text lineClamp={1}>{product.department}</Text>
                </Group>
                <Group spacing="xs">
                  <IconRulerMeasure size="1rem" />
                  <Text lineClamp={1}>{product.size}</Text>
                </Group>
                <Group spacing="xs">
                  <IconHanger size="1rem" />
                  <Text lineClamp={1}>{product.type}</Text>
                </Group>
                <Text lineClamp={1}>{product.description}</Text>
              </Stack>
            </Text>
          </CardSection>
        </Stack>
      </Grid.Col>
    )
  }

  return (
    <>
      {productEntityState && !isLoading && (
        <>
          {products.length > 0 ? (
            <>
              <Section
                title={
                  <Title order={5}>
                    {type} Products{' '}
                    <Text color="gray.6" fw="normal" span>
                      ({products.length})
                    </Text>
                  </Title>
                }
                rightSection={
                  <Button
                    leftIcon={<IconPlus size="0.8rem" />}
                    onClick={handleNavigate}
                  >
                    New Product
                  </Button>
                }
              >
                <Stack pt="sm">
                  <Input
                    placeholder="Search by title or SKU"
                    value={searchQuery}
                    onChange={(event) =>
                      setSearchQuery(event.currentTarget.value)
                    }
                  />
                </Stack>
              </Section>
              <Grid>{filteredProducts.map(productCard)}</Grid>
            </>
          ) : (
            <>
              <EmptyState
                type={`${type} products`}
                createPath={`../${RoutePaths.PRODUCTS_CREATE}?category=${type}`}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
