export enum EndpointPaths {
  // Auth Endpoints
  AUTH_LOGIN = '/auth/login',
  AUTH_LOGIN_ADMIN = '/auth/login/admin',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_VERIFY_EMAIL = '/auth/confirm-registration',
  AUTH_RESET_PASSWORD = '/auth/reset-password',
  AUTH_RESEND_VERIFICATION_EMAIL = '/auth/resend-confirmation-code',
  AUTH_SIGNUP = '/auth/register',
  AUTH_CONNECT = '/auth/connect',
  AUTH_INVITE = '/auth/invite',

  // Account
  ACCOUNT_CHANGE_PASSWORD = '/auth/change-password',

  // Users
  SEARCH_ACCOUNTS = '/account/search',
  LIST_USERS = '/account',

  // Shop(s)
  SHOP = '/shop',
  SHOP_ALL = '/shop/all',
  SHOP_ENABLE = '/shop/enable',
  SHOP_DISABLE = '/shop/disable',
  SHOP_REMOVE_ACCOUNT = '/shop-account',

  // Brand
  BRAND = '/brand',
  BRANDS_ENABLE = '/brand/enable',
  BRANDS_DISABLE = '/brand/disable',

  // PRODUCT
  PRODUCT = '/product',
  PRODUCT_ENABLE = '/product/enable',
  PRODUCT_DISABLE = '/product/disable',

  // WISHLIST
  WISHLIST = '/wishlist/all',

  // POSTS
  POST = '/post',

  // FOLLOWERS
  FOLLOWERS = '/follower/shop',

  // ORDERS
  ORDER = '/order',
}
