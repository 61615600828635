import { orderAdapter } from '@adapters/orderAdapter'
import { orderItemAdapter } from '@adapters/orderItemAdapter'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { IOrder } from '@interfaces/entities/order/order'
import { IOrderItem } from '@interfaces/entities/orderItem/orderItem'
import {
  Badge,
  Button,
  CardSection,
  Group,
  Menu,
  Text,
  Title,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { StatusEnum } from '@nabthisdev/nabthis-types'
import {
  useGetOrdersQuery,
  useUpdateOrderMutation,
} from '@redux/order/orderEndpoints'
import { selectActiveShopId } from '@redux/site/siteSlice'
import { IconStatusChange } from '@tabler/icons-react'

export const List = (): JSX.Element => {
  const activeShop = useAppSelector(selectActiveShopId)
  const [updateOrder] = useUpdateOrderMutation()
  const { data, isLoading, error } = useGetOrdersQuery(activeShop, {
    skip: !activeShop,
  })

  const orders =
    data && !isLoading && !error
      ? orderAdapter.getSelectors().selectAll(data)
      : []

  const handleOrderUpdate = async (order: IOrderItem, status: StatusEnum) => {
    const { shopId, accountId, title, sku, type, category, permalink } = order
    try {
      await updateOrder({
        shopId,
        body: {
          shopId,
          accountId,
          status,
          item: {
            title,
            sku,
            type,
            category,
            permalink,
          },
        },
      }).unwrap()

      showNotification({
        message: `Order item status updated to ${status}`,
        color: 'green',
      })
    } catch (err) {
      showNotification({
        message: `Unable to update order item status`,
        color: 'red',
      })
    }
  }

  const renderOrderItems = (order: IOrder) => {
    const items = orderItemAdapter.getSelectors().selectAll(order.items)

    return items.map((item: IOrderItem, i: number) => (
      <CardSection
        key={`${order.id}${item.id}`}
        p="md"
        withBorder={i !== 0}
        sx={(theme) => ({
          cursor: 'pointer',
          '&:hover': {
            '[data-order-item]': {
              color: theme.colors.violet,
            },
          },
        })}
      >
        <Group position="apart">
          <Group>
            <Text fw="bold" size="sm" color="violet" data-order-item>
              {item.title}
            </Text>
            <Text size="xs">{item.product.cost}</Text>
          </Group>
          <Group>
            {item.status === StatusEnum.ACTIVE && (
              <Badge color="gray">Ready to ship</Badge>
            )}
            {item.status === StatusEnum.COMPLETE && (
              <Badge color="green">Shipped</Badge>
            )}
            <Menu shadow="md" width={200} zIndex={1000} withinPortal>
              <Menu.Target>
                <Button size="xs" color="pink">
                  Update Status
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Update Status to:</Menu.Label>
                <Menu.Item
                  color="gray"
                  onClick={() => handleOrderUpdate(item, StatusEnum.ACTIVE)}
                  icon={<IconStatusChange size={14} />}
                >
                  Ready to ship
                </Menu.Item>

                <Menu.Item
                  color="green"
                  onClick={() => handleOrderUpdate(item, StatusEnum.COMPLETE)}
                  icon={<IconStatusChange size={14} />}
                >
                  Shipped
                </Menu.Item>
                <Menu.Item
                  color="red"
                  onClick={() => handleOrderUpdate(item, StatusEnum.RETURNED)}
                  icon={<IconStatusChange size={14} />}
                >
                  Returned
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </CardSection>
    ))
  }

  return (
    <Section
      title={
        <Title order={5}>
          Orders{' '}
          <Text color="gray.6" fw="normal" span>
            ({orders.length})
          </Text>
        </Title>
      }
    >
      {orders.map((order: IOrder) => renderOrderItems(order))}
    </Section>
  )
}
