import { Shell } from '@components/Shell/Shell'
import { useGetAccountShopsQuery } from '@redux/shop/shopEndpoints'
import { Outlet, ScrollRestoration } from 'react-router-dom'

export const BasePath = (): JSX.Element => {
  const { data: shopEntity } = useGetAccountShopsQuery()

  return (
    <>
      <Shell>
        <Outlet />
      </Shell>
      <ScrollRestoration />
    </>
  )
}
